import React from 'react';
import { graphql, useStaticQuery, Link } from "gatsby";
import ProjectCard from '../ProjectCard';

export default function Projects() {

    const data = useStaticQuery(graphql`
    {
        allMarkdownRemark(
            sort: { fields: [frontmatter___date], order: DESC }
            filter: {collection: {eq: "project"}}
            limit: 3
            ) {
          edges {
            node {
              id
              frontmatter {
                date(formatString: "MMM D, YYYY")
                description
                technologies
                title
                image {
                  childImageSharp {
                    fluid {
                      base64
                      aspectRatio
                      src
                      srcSet
                      sizes
                    }
                  }
                }
              }
              fields {
                slug
              }
            }
          }
        }
      }
    `)
    const projects = data.allMarkdownRemark.edges;

    return (
        <section id="project" className="width-100 m-width-100 t-width-100">

            <Link to="/projects" className='header-link'><h2>Latest Projects</h2></Link>

            <div className="proj-card-container width-100">
              

                {projects.map((project_data, i) => {
                  
                    let img_fluid = project_data.node.frontmatter.image.childImageSharp.fluid;
                    
                    
                    return <ProjectCard img_fluid={img_fluid} frontmatter={project_data.node.frontmatter} slug={project_data.node.fields.slug} key={i}/>

                })}

            </div>
        </section>
    )
}