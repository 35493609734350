import React, { useEffect } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby'

import SolarSystem from '../../assets/solar-system.inline.svg';
import "../../styles/components/home/_landing.scss";

import profilePic1xPNG from "../../assets/solar-system-profile-1x.png";
import profilePic2xPNG from "../../assets/solar-system-profile-2x.png";
import profilePic1xWEBP from "../../assets/solar-system-profile-1x.webp";
import profilePic2xWEBP from "../../assets/solar-system-profile-2x.webp";

import gatsbyLinkify from '../../helpers/gatsbyLinkify';

export default function Landing() {


    useEffect(() => {

        const picture = document.querySelector(`.sun-elem picture`);
        const svg = document.querySelector(`.solar-system`)
        const img = picture.querySelector(`img`);


        if(typeof img.currentSrc !== "undefined") onLoad();
        else img.addEventListener('load', onLoad)

        function onLoad() {
            picture.style.display = '';

            setTimeout(() => {
                picture.classList.add("loaded");
                svg.classList.add("loaded");
            }, 0)

        }


    }, [])


    // OLD
    return (
        <section id="landing" className="flex-container width-100">

            <div className='landing-decorative flex-container'>
                <div className="solar-system-container">
                    <div className="sun-elem">
                        <picture style={{display: "none"}}>
                            <source srcSet={`${profilePic1xWEBP} 1x, ${profilePic2xWEBP} 2x`} type="image/webp" />
                            <source srcSet={`${profilePic1xPNG} 1x, ${profilePic2xPNG} 2x`} type="image/png" />
                            <img src={profilePic1xPNG} alt='' />
                        </picture>
                    </div>
                    <SolarSystem className='solar-system' />
                    
                </div>
            </div>

            <LandingContent />
            
        </section>
    )
}


function LandingContent() {

    

    return (

        <div className="landing-info flex-container-column">
            <h1 className='landing-title'>
                <span>Jonny</span> Mars
            </h1>
            <h2 className='landing-subtitle'>
                <p>I'm a</p> <span>Front-End Web Developer</span>
            </h2>
            <AboutElem />


        </div>

    )

}

function AboutElem() {
    const data = useStaticQuery(graphql`
    {
        allMarkdownRemark(filter: {
              collection: {eq: "about"},
                    frontmatter: { title: { eq: "Short About" }}
            }) {
          edges {
            node {
              html
            }
          }
        }
      }
    `)

    let html = data.allMarkdownRemark.edges[0].node.html;

    


    return (
        <article className="about" className="width-100 m-width-100 t-width-100">
            <div onClick={gatsbyLinkify} dangerouslySetInnerHTML={{__html: html}}></div>
            <Link className='landing-cta button no-link-styles m-width-100' to='/about'>Find Out More</Link>
        </article>
    )


}

// function percentToPixel(elem, percent) {
//     return (elem.parentElement.offsetWidth/100) * Number(percent);
// }