const { navigate } = require("gatsby");

export default function gatsbyLinkify(e) {
    const target_link = e.target.closest('a');
    if(!target_link) return;

    let href = target_link.getAttribute('href');
    
    if(href && href[0] === '/') {
        e.preventDefault();
        navigate(href);
    }

}
