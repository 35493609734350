import React from 'react'
import { Link } from 'gatsby';
// import BackgroundImage from 'gatsby-background-image';
import Img from "gatsby-image";

import "../styles/components/_project_card.scss";

export default function ProjectCard({img_fluid, frontmatter, slug}) {
    
    const {date, description, title} = frontmatter;     

    return (

        <div className='project-card flex-container width-100'>
          <div className="project-card__inner flex-container width-100">

              <div className='project-card__inner__content width-50 m-width-100'>
                  <Link to={`/projects/post${slug}`}><span className="project-card__inner__content__title">{title}</span></Link>
                  <span className='project-card__inner__content__date'>{date}</span>
                  <p className="project-card__inner__content__description">{description}</p>
                  <Link to={`/projects/post${slug}`} className='button'>Read Post</Link>
              </div>
              <Link to={`/projects/post${slug}`} className="project-card__inner__img width-50 m-width-75">
                  <Img fluid={img_fluid} />   
              </Link>
          </div>
        </div>

    )
}
