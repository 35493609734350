import React from "react";
import Layout from "../components/layout";

import Landing from "../components/home/Landing"
import Blog from "../components/home/Blog";
import Project from "../components/home/Project";

import "../styles/components/home/_home-global.scss";

const IndexPage = () => (
  <Layout location='landing' meta_title={'Home'}>
    <Landing />
    
    <div className="width-restricted-container flex-container">
      <Blog />
      <Project />
    </div>


  </Layout>
)

export default IndexPage
