import React from 'react';
import { graphql, useStaticQuery, Link } from "gatsby";
import BlogCard from '../BlogCard';

export default function Blog() {

    const data = useStaticQuery(graphql`
    {
        allMarkdownRemark(
          sort: { fields: [frontmatter___date], order: DESC }
          filter: {collection: {eq: "blogpost"}}
          limit: 3
          ) {
          edges {
            node {
              id
              frontmatter {
                title
                category
                tags
                description
                date(formatString: "MMM D, YYYY")
                image {
                  childImageSharp {
                    fluid {
                      base64
                      aspectRatio
                      src
                      srcSet
                      sizes
                    }
                  }
                }
              }
              fields {
                slug
              }
            }
          }
        }
      }      
    `)
    const posts = data.allMarkdownRemark.edges;

    return (
        <section id="blog" className="width-100 m-width-100 t-width-100">

            <Link to="/blog" className='header-link'><h2>Latest Blog Posts</h2></Link>
            
            <div className="blog-card-container width-100">
              

                {posts.map((post_data, i) => {

                    let frontmatter = post_data.node.frontmatter;
                    let img_fluid = post_data.node.frontmatter.image.childImageSharp.fluid
                    let slug = post_data.node.fields.slug;

                    return <BlogCard frontmatter={frontmatter} slug={slug} img_fluid={img_fluid} key={i} />

                })}

            </div>
        </section>
    )
}


